import * as React from "react"
import * as styles from "./styles/main.module.scss"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Nie ma takiej strony">
      <div className={styles.error404}>
        <StaticImage src={'../images/404.png'} width={400}/>
        <h1 className={styles.textBlock}>Niestety nie ma takiej strony !</h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage
